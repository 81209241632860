var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.widget.compType === "application",
          expression: "widget.compType === 'application'",
        },
      ],
    },
    [
      [
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_name") } },
          [
            _c("lang-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compName,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compName", $$v)
                },
                expression: "widget.compName",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_code") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compCode,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compCode", $$v)
                },
                expression: "widget.compCode",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_width") } },
          [
            _c("el-input-number", {
              attrs: { min: 0, max: 100 },
              model: {
                value: _vm.widget.properties.width,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "width", $$v)
                },
                expression: "widget.properties.width",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_height") } },
          [
            _c("el-input-number", {
              staticClass: "input",
              attrs: { min: 0 },
              model: {
                value: _vm.widget.properties.height,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "height", $$v)
                },
                expression: "widget.properties.height",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_upload_address") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: { clearable: "" },
              model: {
                value: _vm.widget.properties.action,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "action", $$v)
                },
                expression: "widget.properties.action",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_Jump_mode") } },
          [
            _c(
              "el-select",
              {
                model: {
                  value: _vm.widget.properties.target,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "target", $$v)
                  },
                  expression: "widget.properties.target",
                },
              },
              [
                _c("el-option", {
                  attrs: { label: "新开窗口", value: "_blank" },
                }),
                _c("el-option", {
                  attrs: { label: "替换当前窗口", value: "_self" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: "图标宽度" } },
          [
            _c("el-input-number", {
              attrs: { min: 0 },
              model: {
                value: _vm.widget.properties.iconWidth,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "iconWidth", $$v)
                },
                expression: "widget.properties.iconWidth",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: "图标高度" } },
          [
            _c("el-input-number", {
              staticClass: "input",
              attrs: { min: 0 },
              model: {
                value: _vm.widget.properties.iconHeight,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "iconHeight", $$v)
                },
                expression: "widget.properties.iconHeight",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_display_label") } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.showLabel,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "showLabel", $$v)
                },
                expression: "widget.properties.showLabel",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_label_position") } },
          [
            _c(
              "el-select",
              {
                model: {
                  value: _vm.widget.properties.labelPosition,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "labelPosition", $$v)
                  },
                  expression: "widget.properties.labelPosition",
                },
              },
              [
                _c("el-option", {
                  attrs: { label: _vm.$t("lang_align_left"), value: "left" },
                }),
                _c("el-option", {
                  attrs: { label: _vm.$t("lang_center"), value: "center" },
                }),
                _c("el-option", {
                  attrs: { label: _vm.$t("lang_align_right"), value: "right" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_title_size") } },
          [
            _c("el-input-number", {
              staticClass: "input",
              attrs: { min: 12, max: 30 },
              model: {
                value: _vm.widget.properties.labelFontSize,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "labelFontSize", $$v)
                },
                expression: "widget.properties.labelFontSize",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: "标题加粗" } },
          [
            _c(
              "el-select",
              {
                model: {
                  value: _vm.widget.properties.labelFontBold,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "labelFontBold", $$v)
                  },
                  expression: "widget.properties.labelFontBold",
                },
              },
              [
                _c("el-option", { attrs: { label: "正常", value: 1 } }),
                _c("el-option", { attrs: { label: "加粗", value: 2 } }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_title_Color") } },
          [
            _c("el-color-picker", {
              model: {
                value: _vm.widget.properties.labelFontColor,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "labelFontColor", $$v)
                },
                expression: "widget.properties.labelFontColor",
              },
            }),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }