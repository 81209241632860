


























































import { Component, Mixins, Watch } from 'vue-property-decorator';
import ElePropertiesMixins from './mixins/ElePropertiesMixins';
interface Init {
  iconHeight?: number;
  iconWidth?: number;
}
/**
 * input的配置项
 */
@Component({
  name: 'Application',
  components: {}
})
export default class extends Mixins(ElePropertiesMixins) {
  created() {
    console.log(this.widget);
    this.initData();
  }

  // 初始化数据
  initData(): void {
    const obj: Init = {
      iconHeight: 60,
      iconWidth: 60
    };
    Object.keys(obj).map(keys => {
      this.widget.properties[keys] = obj[keys];
    });
  }
  openSetDataDilog(): void {}
}
